import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { v4 as uuidv4 } from 'uuid'
import {KionPostersResponse, KionPromoCodeResponse, KionUserSubscription} from '@/util/interfaces'
import { Subscription } from '@/util/promo'
import { WebSsoJWT } from "@/util/HashParser"
import client from "@/util/HttpClient"

interface AuthResponse {
  userId: string
  accessToken: string
  refreshToken: string
  login: string
  password: string
  tokenType: string
  expiresIn: number
  firstLogin: boolean
}

interface Promo {
  promoCode: string
  productId: string
  discountPrice: number
}

export interface RelatedSubscriptionsTextRequest {
	productGid: string;
	promoCode: string;
	paymentType: PaymentType;
}

export interface PaymentType {
	source: PaymentTypeEnum;
	system: PaymentSystemEnum;
}

export interface MtsBankWidgetResponse {
  isSuccess: boolean
  errorCode?: string
  message?: string
  eventName: 'successPayment' | 'failPayment' | 'validationError'
}

export interface MtsBankWidgetConfig {
  sessionId: string,
  scenarioType: 'pay',
  tokenId: string,
  environment: 'PROD',
  resultHandler: (resp: MtsBankWidgetResponse) => void
}

interface CancelingProductGids {
    blockingProductGids: any[];
    button: string;
    cancelingProductGids: any[];
    header: string;
    text: string;
  }

// export type PaymentTypeSource = 'CARD' | 'ACCOUNT' | 'UNKNOWN' | 'INAPPLICABLE';

// export const PaymentTypeSourceSystemEnum = {
//   CARD: 'MTS_MONEY',
//   ACCOUNT: 'FORIS_ONLINE',
// } as const;

export const enum PaymentSystemEnum {
	FORIS_ONLINE = 'FORIS_ONLINE',
	MTS_MONEY = 'MTS_MONEY',
}

export const enum PaymentTypeEnum {
	ACCOUNT = 'ACCOUNT',
	CARD = 'CARD',
}

export default CancelingProductGids;

class HttpService {
  get grclientid(): string | null {
    return this._grclientid
  }

  set grclientid(value: string | null) {
    this._grclientid = value
  }

  get userId(): string | null {
    return this._userId
  }

  set userId(value: string | null) {
    this._userId = value
  }
  get webSSOJwt(): WebSsoJWT | { [key: string]: string } | null {
    return this._webSSOJwt
  }

  set webSSOJwt(value: WebSsoJWT | { [key: string]: string } | null) {
    this._webSSOJwt = value
  }

  private token: null | string = null
  private _jwtToken = ''
  private _webSSOJwt: null | WebSsoJWT | { [key: string]: string } = null
  private _promoCode = ''
  private _previousPage = ''
  private _messageError = ''
  private _phone = ''
  private _userId: null | string = null
  private _grclientid: null | string = null
  private _clientTerminalId = ''
  public promo: Promo = {
    promoCode: '',
    productId: '',
    discountPrice: 0
  }

  private _http = client

  private post<T>(
    endpoint: string,
    payload: { [key: string]: unknown } | string = {},
    externalOptions = {}
  ): Promise<AxiosResponse<T>> {
    let options: AxiosRequestConfig = {
      ...externalOptions
      // withCredentials: true
    }

    if (this.token) {
      options = {
        ...options,
        headers: {
          'Authorization': `Bearer ${this.token}`
        }
      }
    }

    return this._http.post<T>(endpoint, payload, options)
  }

  public auth(nonce: string, webssoCode: string): Promise<AxiosResponse<AuthResponse>> {
    const clientTerminalId = this.clientTerminalId = this.phone + '-' + uuidv4()
    return this.post<AuthResponse>(
      '/ott/actions/websso-login',
      {
        clientTerminalId,
        terminalType: '2',
        deviceModel: 'Landing',
        deviceName: 'kion_ru_code',
        deviceOS: 'Landing KION',
        refreshTokenSupported: false,
        webssoCode,
        nonce
      }
    )
  }

  public getUserId() {
    const options: AxiosRequestConfig = {
      baseURL: '/code/coreapi',
      headers: {
        'Authorization': `Bearer ${this.token}`
      }
    }
    return this._http.get<{ huaweiSubscriberId: string }>('/subscribers/me', options)
  }

  public checkSubscription(data: RelatedSubscriptionsTextRequest) {
    const options: AxiosRequestConfig = {
      baseURL: '/code/tvh-subscriptions',
      headers: {
        'Authorization': `Bearer ${this.token}`
      }
    }
    return this._http.post<CancelingProductGids>('/subscription/related-subscriptions-text', data, options)
  }

  public payWithPhone({ productId, discountPrice }: Subscription) {
    const options: AxiosRequestConfig = {
      baseURL: '/code/subscriptionapi'
    }
    const payload = {
      productId,
      price: discountPrice,
      programId: null,
      promoCode: this.promoCode,
      clientTerminalId: this.clientTerminalId
    }
    return this.post('/subscriptions/v2', payload, options)
  }

  public async getWidgetConfig(subscription: Subscription, callback: (resp: MtsBankWidgetResponse) => void): Promise<MtsBankWidgetConfig> {
    // const { webssoSessionToken } = (await this.getPaymentToken()).data
    const { sessionId } = (await this.getPaymentSession(subscription)).data
    return new Promise<MtsBankWidgetConfig>(res => res({
      sessionId, //sessionId,
      scenarioType: 'pay',
      tokenId: this.jwtToken,
      environment: 'PROD',
      resultHandler: callback
    }))
  }

  private getPaymentSession({ productId, discountPrice }: Subscription) {
    const options: AxiosRequestConfig = {
      baseURL: '/code/cardapi'
    }

    const payload: { [key: string]: null | string | number | boolean } = {
      productId,
      price: discountPrice,
      programId: null,
      marketingType: this.promoCode.toLowerCase() !== 'kionmir' ? 'LANDING' : 'MIR_CASHBACK',
      promocode: this.promoCode,
      support3ds: true
    }

    return this.post<{ sessionId: string }>('/payment/create-session', payload, options)
  }

  // private getPaymentToken() {
  //   const options: AxiosRequestConfig = {
  //     baseURL: process.env.NODE_ENV === 'development' ? '/privateapi' : 'https://prod.tvh.mts.ru/tvh-api-gateway/rest'
  //   }
  //
  //   return this.post<{ webssoSessionToken: string }>('/general/actions/get-websso-session-token', {}, options)
  // }

  public getLastOfferMarkup() {
    const options: AxiosRequestConfig = {
      baseURL: '/code/offertapi'
    }
    return this.post('/offers/actions/get-last-published-offer', {}, options)
  }

  public getSubscriptionList() {
    const { promoCode } = this.promo
    const options: AxiosRequestConfig = {
      baseURL: '/code/gatewayapi'
    }
    return this.post<KionPromoCodeResponse>('/products/v2/get-promo-products', { promoCode }, options)
  }

  public getImgsForSwapper() {
    const config: AxiosRequestConfig = {
      baseURL: '/code/shelvesapi',
      headers: {
        'X-Device-Model': 'AndroidPhone_Widevine_VMX_v3',
        'X-Device-Id': 'kion_ru_code',
        'X-App-Version': '1'
      }
    }

    return this._http.get<KionPostersResponse>('/shelves/glo_shelf_blender_1344', config)
  }

  public getImgsForSerial() {
    const config: AxiosRequestConfig = {
      baseURL: '/code/shelvesapi',
      headers: {
        'X-Device-Model': 'AndroidPhone_Widevine_VMX_v3',
        'X-Device-Id': 'kion_ru_code',
        'X-App-Version': '1'
      }
    }

    return this._http.get<KionPostersResponse>('/shelves/glo_shelf_cm_1583', config)
  }

  public isMtsAccount() {
    const options: AxiosRequestConfig = {
      baseURL: '/code/privateapi',
      headers: {
        'Authorization': `Bearer ${ this.token }`
      }
    }
    return this._http.get<{saleBlock: null | string}>('/account/me', options)
  }

  public filterAppliedSubscriptions({ productId }: Subscription) {
    const options: AxiosRequestConfig = {
      baseURL: '/code/subscriptionapi'
    }

    return this.post<KionPromoCodeResponse>('/subscriptions/actions/get-subscriptions-by-filter', { productId }, options)
  }

  public getUsesSubscriptions() {
    const options: AxiosRequestConfig = {
      baseURL: '/code/subscriptionapi',
      headers: {
        'Authorization': `Bearer ${ this.token }`
      }
    }
    return this._http.get<KionUserSubscription[]>('/subscriptions', options)

  }

  get phone() {
    return this._phone
  }

  set phone(phone: string) {
    this._phone = phone
  }

  get jwtToken() {
    return this._jwtToken
  }

  set jwtToken(str: string) {
    this._jwtToken = str
  }

  get clientTerminalId(): string {
    return this._clientTerminalId
  }

  set clientTerminalId(value: string) {
    this._clientTerminalId = value
  }

  public set promoCode(str: string) {
    this.promo.promoCode = str.toUpperCase()
    this._promoCode = str.toUpperCase()
  }

  public get promoCode(): string {
    return this._promoCode
  }

  public set messageError(str: string) {
    this._messageError = str
  }

  public get messageError(): string {
    return this._messageError
  }

  public set previousPage(str: string) {
    this._previousPage = str
  }

  public get previousPage(): string {
    return this._previousPage
  }

  public set accessToken(str: string) {
    this.token = str
  }

}

export const tvhEnvironmentApi = 'prod.tvh.mts.ru'

export const httpService = new HttpService()
