
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class KionHeader extends Vue {
  path = this.computeImgPath()

  mounted() {
    window.addEventListener('resize', this.resizeHandler)
  }

  destoyed() {
    window.removeEventListener('resize', this.resizeHandler)
  }

  computeImgPath(): string {
    const width = window.innerWidth
    if (width > 411) {
      return require('./assets/logo-lg.svg')
    } else if (width > 360) {
      return require('./assets/logo-md.svg')
    }
    return require('./assets/logo-sm.svg')
  }

  resizeHandler() {
    this.path = this.computeImgPath()
  }
}
